import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const clientroutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/account/:type',
    name: 'contactAccount',
    component: () => import('../views/contact/Account.vue')
  },
  // Dossier  
  {
    path: '/me/dossiers',
    name: 'myDossiers',
    component: () => import('../views/dossier/MyDossiers.vue')
  },
  {
    path: '/me/dossiers/:dossierId/:stage?',
    name: 'myDossier',
    component: () => import('../views/dossier/MyDossier.vue')
  },
]

// Admin
const adminroutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'adminHome',
    component: () => import('../views/admin/Home.vue')
  },
  {
    path: '/contacts',
    name: 'adminContacts',
    component: () => import('../views/admin/Contacts.vue')
  },
  {
    path: '/contact/:id',
    name: 'adminContactDetail',
    component: () => import('../views/admin/ContactDetail.vue')
  },
  {
    path: '/dossiers',
    name: 'adminDossiers',
    component: () => import('../views/admin/Dossiers.vue')
  },
  {
    path: '/dossier/:id',
    name: 'adminDossierDetail',
    component: () => import('../views/admin/DossierDetail.vue')
  },
  {
    path: '/sources',
    name: 'adminSources',
    component: () => import('../views/admin/Sources.vue')
  },
  {
    path: '/pension-companies',
    name: 'adminPensionCompanies',
    component: () => import('../views/admin/PensionCompanies.vue')
  },
  {
    path: '/users',
    name: 'adminUsers',
    component: () => import('../views/admin/Users.vue')
  },
  {
    path: '/invoices',
    name: 'adminInvoices',
    component: () => import('../views/admin/Invoices.vue')
  },
  {
    path: '/login',
    name: 'adminLogin',
    component: () => import('../views/admin/Login.vue')
  },
  {
    path: '/logs',
    name: 'contactLogs',
    component: () => import('../views/admin/ContactLogs.vue')
  }
  
]

const router = createRouter({
  history: createWebHistory(),
  routes: (window.location.host.split('.')[0] == 'portal' || window.location.host.split('.')[0] == 'portaal' || window.location.host.split('.')[0] == 'mariadb-portaal' ? adminroutes : clientroutes)
})

export default router
